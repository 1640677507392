.about-section {
  /* height: 100vh; */
  display: flex;
  justify-content: center;
  padding-top: 100px;
}

.aboutmernstackdev {
  padding: 20px 0;
}
.aboutMern {
  background-image: var(--bg);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
.aboutmeText {
  font-weight: bold;
  font-size: 35px;
}
.myNameAbout {
  font-size: 30px;
  font-weight: bold;
  color: var(--text);
}
.aboutMernstack {
  font-size: 18px;
}
.emailAbout {
  padding-right: 40px;
  border: 1px solid var(--text);
  border-top: none;
  border-left: none;
  border-bottom: none;
  font-weight: bold;
}
.emailadressabout {
  padding-left: 20px;
  color: black;
}
.emailadressabout:hover {
  color: blue;
}
.aboutinfolink:hover {
  text-decoration: none;
}

.mobileabout {
  padding-left: 20px;
  color: black;
}
.mobileabout:hover {
  color: blue;
}
.phoneabout {
  padding-right: 40px;
  border: 1px solid var(--text);
  border-top: none;
  border-left: none;
  border-bottom: none;
  font-weight: bold;
}
.freelancer {
  padding-right: 16px;
  border: 1px solid var(--text);
  border-top: none;
  border-left: none;
  border-bottom: none;
  font-weight: bold;
}
.availableabout {
  padding-left: 20px;
}
.imageabout {
  width: 75%;
  border: 15px solid var(--text);
}
.myResume {
  margin-top: 100px;
  font-size: 35px;
  font-weight: bold;
}
.aboutEducationAvailable {
  margin-bottom: 70px;
  padding-top: 20px;
}
.Education {
  font-weight: bold;
  margin-bottom: 30px;
}
.Experience {
  font-weight: bold;
  margin-bottom: 30px;
}
.degreeName {
  color: var(--text);
}
.TechnicalSkills {
  margin: 70px 0;
}
.techSkill {
  font-size: 35px;
  font-weight: bold;
}
.specializeIn {
  font-size: 14px;
  padding: 20px 0;
}
@media (max-width: 992px) {
  .imageabout {
    width: 80%;
    margin-left: 10%;
    margin-top: 20px;
  }
  .about-me {
    margin-left: 10%;
    margin-top: 50px;
  }
}
@media (max-width: 576px) {
  .about-me {
    margin-left: 0%;
    margin-top: 50px;
  }
}
@media (max-width: 364px) {
  .emailAbout {
    padding-right: 30px;
    font-size: 12px;
  }
  .phoneabout {
    padding-right: 30px;
    font-size: 12px;
  }
  .freelancer {
    padding-right: 14px;
    font-size: 12px;
  }
  .emailadressabout {
    padding-left: 5px;
    font-size: 12px;
  }

  .mobileabout {
    padding-left: 5px;
    font-size: 12px;
  }
  .availableabout {
    padding-left: 5px;
    font-size: 12px;
  }
}
