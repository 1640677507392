body {
  margin-top: 20px;
}
.section_padding {
  padding-top: 70px;
  padding-bottom: 70px;
}
.FAQ-head {
  color: var(--text);
  font-weight: bold;
  font-size: 35px;
}
.FAQ-P {
  padding: 20px 0;
}
.faq_area {
  position: relative;
  z-index: 1;
}

.faq-accordian {
  position: relative;
  z-index: 1;
}
.faq-accordian .card {
  position: relative;
  z-index: 1;
  margin-bottom: 1.5rem;
}
.faq-accordian .card:last-child {
  margin-bottom: 0;
}
.faq-accordian .card .card-header {
  background-color: #ffffff;
  padding: 0;
  border-bottom-color: #ebebeb;
}
.faq-accordian .card .card-header h6 {
  cursor: pointer;
  padding: 1.75rem 2rem;
  background: var(--bg);
  color: var(--white);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -ms-grid-row-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.faq-accordian .card .card-header h6 span {
  font-size: 1.5rem;
}
.faq-accordian .card .card-header h6.collapsed {
  color: var(--white);
}
.faq-accordian .card .card-header h6.collapsed span {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.faq-accordian .card .card-body {
  padding: 1.75rem 2rem;
}
.faq-accordian .card .card-body p:last-child {
  margin-bottom: 0;
}

@media only screen and (max-width: 575px) {
  .support-button p {
    font-size: 14px;
  }
}

.support-button i {
  color: var(--text);
  font-size: 1.25rem;
}
@media only screen and (max-width: 575px) {
  .support-button i {
    font-size: 1rem;
  }
}

.support-button a {
  text-transform: capitalize;
  color: #2ecc71;
}
@media only screen and (max-width: 575px) {
  .support-button a {
    font-size: 13px;
  }
}
